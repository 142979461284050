<template>
  <div class="container">
    <section class="banner"></section>
    <section>
      <div class="checkbox">
        <span>分类：</span>
        <el-checkbox-group v-model="product">
          <el-checkbox-button v-for="(item,index) in productOptions" :label="item.id" :key="index">{{ item.name }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class="checkbox">
        <span>格式：</span>
        <el-checkbox-group v-model="status">
          <el-checkbox-button v-for="(item,index) in statusOptions" :label="item.value" :key="index">{{ item.label }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>
    </section>
    <section>
      <div class="content">
        <div class="crumbs">
          当前位置： 全部
        </div>
        <div class="search">
          <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="keywords">
          </el-input>
        </div>
      </div>
    </section>
    <section>
      <ul class="courseList">
        <li class="course-item" v-for="(item,index) in courseList" :key="index">
          <router-link :to="{path:'/course/detail?id='+item.id}" class="card">

            <div class="thumbnail">
              <img :src="item.upload_file?item.upload_file:thumbnail" alt="">
              <span>{{ item.length }}</span>
            </div>
            <div class="info-box">
              <div class="title">{{ item.title }}</div>
              <div class="info">
                <div class="info-left">
                  <img class="avatar" :src="item.avatar?item.avatar:defaultAvatar" alt="">
                  <span>{{ item.lecturer || '暂无讲师' }}</span>
                </div>
                <div class="info-right">
                  <span>{{ item.heat }}</span>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="page-Wrap">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage3"
            :total="pageProps.total"
            :page-size="pageProps.pageSize"
            :current-page="pageProps.pageNum"
            :page-sizes="[5, 10, 50, 100]"
            layout="prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import {getSyllabusList} from "@/api/syllabus/index";

export default {
  data() {
    return {
      currentPage3:1,
      status: [''],
      complete: [''],
      product: [''],
      keywords: '',
      productOptions: [
        {
          id: 0,
          name: '全部'
        },
      ],
      statusOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '未开始'
        },
        {
          value: '2',
          label: '进行中'
        },
        {
          value: '3',
          label: '已结束'
        }
      ],
      completeOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '已完成'
        },
        {
          value: '2',
          label: '未完成'
        },
      ],
      courseList: [],
      pageProps: {
        records: [],
        total: 0,
        pageSize: 20,
        pageNum: 1
      }
    };
  },
  methods: {
    handleSizeChange(val) {

    },
    handleCurrentChange(val) {

    },
    currentPage3() {

    }
  },


  mounted() {
    this.getSyllabusList();

  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.initNav();
      },
      // 深度观察监听
      deep: true
    },

  },

  methods: {

    getSyllabusList() {

      getSyllabusList({list_rows: this.pageProps.pageSize, page: this.pageProps.pageNum}).then(res => {
        if (res.code == 200) {
          this.courseList = res.data.course.data;
          this.pageProps.total = res.data.course.total;
          this.productOptions.product(res.data.class);
        }
      })
    },

  }
};
</script>

<style lang="scss" scoped>
.container {
  .banner {
    width: 100%;
    height: 200px;
    background: url('../../assets/image/banner1.png');
  }

  .courseList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px; /* 设置网格间距 */

    padding: 10px; /* 添加 padding */
    border-radius: 10px; /* 添加圆角 */

    // justify-content: space-between;
    background-color: #eff1f4;

    .course-item {
      margin: 10px; /* 设置间距 */
      border-radius: 10px; /* 添加圆角 */
      overflow: hidden; /* 隐藏超出圆角的部分 */
      margin-right: 24px;

      

      ul li {
        border-radius: 10px; /* 添加圆角 */
      }

      .card {
        position: relative;
        background-color: #fff;
        transition: all .3s ease;
        display: inline-block;

        &:hover {
          transform: translateY(-8px);
          box-shadow: 0 6px 12px 2px #f0f0f0;
        }

        .info-box {
          padding: 20px;

          .title {
            line-height: 24px;
            min-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
            font-size: 18px;
            font-weight: 500;
            color: #333;

          }

          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            .info-left {
              display: flex;
              align-items: center;

              .avatar {
                width: 30px;
                height: 30px;
                margin-right: 10px;
              }
            }
          }
        }

        &:hover .title {
          color: #1890ff;
        }

        .thumbnail {
          width: 282px;
          height: 158px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          span {
            position: absolute;
            right: 35px;
            bottom: 10px;
            font-size: 12px;
            color: #fff;
          }
        }
      }

    }
  }

  .checkbox {
    display: flex;
    border-bottom: 1px dashed #e2e2e2;
    padding: 0 10px;
    height: 62px;
    background: #fff;
    align-items: center;

    span {
      font-size: 14px;
      color: #999;
    }

    /deep/ .el-checkbox-button {
      padding: 0 5px;
      margin: 5px 35px 5px 0;

      cursor: pointer;
      transition: all .3s;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      border: none;
      box-sizing: border-box;
      height: 24px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: row;
    }

    /deep/ .el-checkbox-button__inner {
      border: none;
      font-size: 14px;
      color: #999;
      height: 24px;
      box-sizing: border-box;
      line-height: 24px;
      padding: 0 5px;
      border-radius: 4px;

      &:hover {
        color: #266fff;
      }
    }

    /deep/ .is-checked .el-checkbox-button__inner {
      background-color: #266fff;
      color: #fff;
    }

  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 0 16px;
    box-sizing: border-box;

    .crumbs {
      font-size: 14px;
      color: #999;
    }

    .search {
      width: 220px;
      height: 32px;

      /deep/ .el-input__inner {
        border-radius: 16px;
      }
    }
  }

  .list-box {
    border-radius: 6px;

    .item {
      border-radius: 6px;

      .card {
        display: inline-block;
        background-color: #fff;
        transition: all .3s ease;
        border-radius: 6px;

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04);
        }

        .top {
          height: 100px;
          padding: 0 28px 0 44px;
          background-color: #69708b;
          color: #fff;
          font-size: 28px;
          line-height: 100px;
          min-height: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
          width: 588px;
          box-sizing: border-box;
          border-radius: 6px 6px 0 0;

          &::after {
            content: "";
            width: 4px;
            height: 28px;
            background-color: #fff;
            position: absolute;
            left: 28px;
            top: 36px;
          }
        }

        .info {
          padding: 28px;
          box-sizing: border-box;
          background: #fff;
          border-radius: 0 0 6px 6px;

          .time {
            color: #999;
            font-size: 18px;
            line-height: 1.5;
          }

          .bottom {
            display: flex;
            justify-content: space-between;

            .text {
              display: flex;
              justify-content: unset;
              align-items: unset;
              flex-direction: row;
              color: #666;
              font-size: 18px;
              line-height: 1.5;
              margin-top: 36px;
            }

            .progress {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              flex-direction: row;

              .num {
                font-size: 26px;
                color: #1ab700;
                margin-right: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.page-Wrap {
  padding: 40px 0;
  text-align: center;
}

</style>
